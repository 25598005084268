import React from 'react'
import { Link } from 'react-router-dom'

const FooterThree = () => {
  return (
    <div className='footer-gray'>
    <div className="container ">     
    <div className="banner-content">
        <div className='row justify-content-center'>
            <div className='col-md-11 col-lg-10'>
                <div className='d-flex justify-content-between flex-column flex-md-row align-items-md-end'>
                    <div className=''>
                        <img src="/images/icon/brand_icon.png" alt="" />
                    </div>
                    <div className='mt-3 mt-md-0  d-flex justify-content-around'>
                        <Link className='footer-link me-3' to='' >Privacy</Link>
                        <Link className='footer-link' to=''>Terms of Service</Link>
                    </div>
                </div>
                <hr className='my-3'/>
                <small className='footer-copy-right'>By using this website you are agreeing to our terms of service.</small>
            </div>
        </div>
    </div>
    </div>
    </div>
   
  )
}

export default FooterThree