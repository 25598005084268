import React from 'react'

const BannerNine = () => {
  return (
    <div className=' banner-light-gray'>
    <div className="container py-5">     
    <div className="banner-content">
        <div className='row justify-content-center'>
            <div className='col-md-11 col-lg-10'>
            <div className='row align-items-center flex-column-reverse flex-lg-row'>
           
                <div className='col-lg-6 mt-4 mt-lg-0'>
                <h3>Pro Tip:</h3>
                <p className='text-light-gray'>
                A simple way to make the most of our service is to pre-load your queue with as many projects for us to work on all at once. If you're struggling to think about what to delegate to our team, take yourself out to a local coffee shop or walk in the park and think about all of those internal projects that you've put on hold because they are not on the priority list.
                </p>
               
               
                </div>

                <div className='col-lg-6 mt-3 mt-lg-0 text-center'>
                <img src='/images/banner/banner-thumb-new3.png' alt='banner' />
                </div>
                
            </div>
            </div>
        </div>
    </div>               
</div>
    </div>
    
  )
}

export default BannerNine