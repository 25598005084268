import React from 'react'

const BannerSeven = () => {
  return (

    <div className="container">     
                <div className="banner-content">
                    <div className='row justify-content-center'>
                        <div className='col-md-11 col-lg-10'>
                        <div className='row align-items-center '>
                        <div className='col-lg-6  text-center'>
                        <img src='/images/banner/banner-thumb-new1.png' alt='banner' />
                        </div>
                            <div className='col-lg-6 mb-5 mb-lg-0'>
                            <h3>Onboarding Call </h3>
                            <p className='text-light-gray mb-4'>What to expect with the onboarding call</p>
                            <ol className='ps-4 text-light-gray mb-4'>
                                <li className='mb-2'>
                                How to join Distortion Studios Private Discord <br/> Channel to talk with your team.
                                </li>
                                <li>
                                How to submit your Job Request.
                                </li>
                            </ol>
                            <button className='axil-btn axil-btn-green btn-fill-primary'>Book Onboarding Call Now!</button>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </div>               
</div>
  )
}

export default BannerSeven


