import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerEight from '../component/banner/BannerEight';
import BannerSeven from '../component/banner/BannerSeven';
import BannerSix from '../component/banner/BannerSix';
import FooterThree from '../common/footer/FooterThree'
import BannerNine from '../component/banner/BannerNine';


const Home = () => {

    return (
        <>
        <SEO title=""/>
        <main className="Home">
        <span class="shape shape-1">
        <img src="/images/others/bubble-39.png" alt="Bubble" />
        </span>
            <HeaderOne />
            <BannerSix />
            <BannerSeven />
            <BannerEight />
            <BannerNine />

           
        <FooterThree />
        </main>
        </>
    )
}

export default Home;

