import React from 'react'

const BannerSix = () => {
    return (
        <div className="banner banner-six ">
        
            <div className="container">
                
                    
                        <div className="">
                            <div className='row justify-content-center'>
                                <div className='col-md-11 col-xl-8 text-md-center'>
                                <h2>                     
                                Excited to Start Working with <br className='d-none d-lg-inline'/> Your New Team?
                            </h2>
                            <h4 className='text-light-gray'>
                            Please watch the video below and then work your <br className='d-none d-md-inline'/> way through the onboarding steps to get started.
                            </h4>
                            <div className='text-center my-5'>
                            <img src='/images/banner/banner-thumb-new.png' alt='banner' style={{borderRadius:'20px'}} />
                            </div>
                                </div>
                            </div>
                        </div>
                    
                        
                        
                           
            </div>
        </div>
    )
}

export default BannerSix