import React from 'react'

const BannerEight = () => {
  return (
    <div className="container mb-5">     
                <div className="banner-content">
                    <div className='row justify-content-center'>
                        <div className='col-md-11 col-lg-10'>
                        <div className='row align-items-center flex-column-reverse flex-lg-row'>
                       
                            <div className='col-lg-6 mb-5 mb-lg-0'>
                            <h3>Submit your Job Request</h3>
                            <p className='text-light-gray mb-4'>Let’s start your Job request by clicking the button below.</p>
                           
                            <button className='axil-btn axil-btn-green btn-fill-primary'>Submit your Job Request</button>
                            </div>

                            <div className='col-lg-6 mt-3 mt-lg-0 text-center'>
                            <img src='/images/banner/banner-thumb-new2.png'  alt='banner'/>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </div>               
</div>
  )
}

export default BannerEight