import React from "react";
import PropTypes from "prop-types";

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>
        Distortion Studios | Web3 tech, marketing and animation studio | {title}
      </title>
      <meta name="author" content="Distortion Studios"></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:url" content="https://distu.xyz/"></meta>
      <meta property="og:site_name" content="Distortion Studios"></meta>
      <meta
        property="og:description"
        content="Distortion Studios is a multi-media studio servicing both physical and digital worlds"
      ></meta>
      <meta
        property="og:image"
        content="https://github.com/javiperezt/DISTU_NEW_SITE/blob/55c4019261258e648f601aaefd0256d124056197/public/images/custom/logo.png"
      ></meta>
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
